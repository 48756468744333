import type { Shop, User } from "@/types";
import { Percent } from "lucide-react";

interface Props {
  shop: Shop;
  user?: User | null;
}

const Marquee = ({ shop, user }: Props) => {
  return (
    <div className="bg-kp-primary text-kp-white py-2 overflow-hidden">
      <div className="relative flex overflow-x-hidden">
        <div className="animate-marquee whitespace-nowrap flex items-center">
          <Percent className="text-kp-accent mx-2" size={24} />
          <span className="mx-4 text-lg font-bold">
            BLACK FRIDAY DEALS! 50% OFF ALL SUITCASES
          </span>
          <Percent className="text-kp-accent mx-2" size={24} />
          <span className="mx-4 text-lg font-bold">
            FREE SHIPPING ON ORDERS OVER KES 50,000
          </span>
          <Percent className="text-kp-accent mx-2" size={24} />
          <span className="mx-4 text-lg font-bold">
            BLACK FRIDAY DEALS! 50% OFF ALL SUITCASES
          </span>
          <Percent className="text-kp-accent mx-2" size={24} />
          <span className="mx-4 text-lg font-bold">
            FREE SHIPPING ON ORDERS OVER KES 50,000
          </span>
        </div>
        <div className="absolute top-0 animate-marquee2 whitespace-nowrap flex items-center">
          <Percent className="text-kp-accent mx-2" size={24} />
          <span className="mx-4 text-lg font-bold">
            BLACK FRIDAY DEALS! 50% OFF ALL SUITCASES
          </span>
          <Percent className="text-kp-accent mx-2" size={24} />
          <span className="mx-4 text-lg font-bold">
            FREE SHIPPING ON ORDERS OVER KES 50,000
          </span>
          <Percent className="text-kp-accent mx-2" size={24} />
          <span className="mx-4 text-lg font-bold">
            BLACK FRIDAY DEALS! 50% OFF ALL SUITCASES
          </span>
          <Percent className="text-kp-accent mx-2" size={24} />
          <span className="mx-4 text-lg font-bold">
            FREE SHIPPING ON ORDERS OVER KES 50,000
          </span>
        </div>
      </div>
    </div>
  );
};

export default Marquee;
